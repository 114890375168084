
import '../App.css';

function PrivacyPolicy() {


  return (
    <div className="App-Legal-Text">
    <div className="Section-Padding"/>

    <h1 id="privacy-policy">Privacy Policy</h1>
<h2 id="last-updated-february-10-2022">Last Updated: February 10, 2022</h2>
<p>Regulus: Star Diary App (“Regulus App”, “Regulus”,  “app,” “we,” “us,” and “our”) are committed to protecting your personal information. This Privacy Policy explains how Regulus: Star Diary App may collect, store, use, and disclose information when you access or use (1) our website located at regulusapp.wordpress.com (“Website”); (2) Regulus: Star Diary Application (“App”); and (3) any services, content, and features made available by us through the website or apps (together with the Website and Apps, “Services”).</p>
<h1 id="your-acceptance-of-this-privacy-policy">Your acceptance of this privacy policy</h1>
<p>This Privacy Policy is applicable to all visitors, registered users, and all other users of our Services. By accessing or using the Services, you consent to us collecting, storing, using, and disclosing your information as described in this Privacy Policy. The provisions contained in this Privacy Policy supersede all previous notices and statements regarding our privacy practices with respect to the Services. If you do not agree to all provisions of this Privacy Policy, you may not access or use the Services. We reserve the right to periodically update or modify this Privacy Policy. If we make any material changes to this Privacy Policy or the way we use, share, or collect Personal Information, we will notify you by revising the “Last Updated” at the top of this Privacy Policy, prominently posting an announcement of the changes on our Services, or by sending you a written communication using the contact information you provided us prior to the new policy taking effect. Users are bound by any changes to the Privacy Policy after notice of such changes has been communicated. We encourage you to review this Privacy Policy regularly to ensure that you are familiar with our current practices.</p>
<h1 id="information-we-get-from-you">Information We Get from You</h1>
<p>When you use our Services, we may collect information that you provide to us. Some of this information may identify you (“Personal Information”), and some of this information may not identify you (“Anonymous Information”). Sometimes, Anonymous Information may directly or indirectly be associated or combined with Personal information. In these cases, such Anonymous Information will be considered Personal Information for purposes of this Privacy Policy. In addition, to the extent that Internet Protocol (“IP”) addresses or similar identifiers or information are considered personally identifiable information by applicable law, we will treat those items as Personal Information under this Privacy Policy.</p>
<h1 id="information-you-provide-to-us">Information You Provide to Us</h1>
<p>In general, you may use the app without telling us who you are or sharing any Personal Information with us. When you create a profile, we may ask you to provide certain Personal Information, as set forth below. You may decline to do so, in which case, some features of the Services may not be available, may be limited in performance, or may not work at all. You may also voluntarily provide Personal Information to us. The information that we collect and use may include the following kinds of Personal Information:</p>
<ul>
<li>Your name <em>(you may enter a nickname, yo do not have to write your legal name)</em></li>
<li>Birth date</li>
<li>Birth time</li>
<li>Birth place <em>(city or district)</em></li>
<li>Current location <em>(city or district)</em>
<em>You may edit or delete these information anytime you want</em></li>
</ul>
<h1 id="information-collected-from-devices">Information Collected from Devices</h1>
<p>When you access the Services using a computer or mobile device, including, without limitation, a desktop computer, laptop, mobile phone, tablet, or other consumer electronic device (each, a “Device”), we may collect information from your Device, including information related to your behavior and activity while using the Services, such as:</p>
<ul>
<li>Operating system and version</li>
<li>Local time zone</li>
<li>Date, time, and activity of each visitor request</li>
<li>Device type (e.g. desktop, laptop, tablet, phone, etc.)</li>
<li>Screen size</li>
<li>Language preference</li>
</ul>
<h1 id="information-collected-by-cookies-and-similar-technologies">Information Collected by Cookies and Similar Technologies</h1>
<p>When you access or use the Services, we use Google Analytics to assign random, unique numbers to your Device by placing small data files on your device. These data files enable our Services to work properly by recognizing your Device when you use the Services. These data files may be cookies, pixel tags, other local shared objects, or similar technologies provided by your browser or associated applications (collectively, “Cookies”). Google Analytics generates a Cookie to capture information about your use of the Services. Cookies are used to compile anonymized information about website activity to improve our Services and to provide other related services. Google Analytics may use a portion of your IP address to identify its Cookie.
If you prefer not to receive Cookies through the Services, you may opt out of Google Analytics or control how your browser responds to Cookies by adjusting the privacy and security settings of your web browser. Unless you set your browser settings to refuse all Cookies, our system may issue Cookies when you access or use the Services.</p>
<h1 id="how-we-use-information">How We Use Information</h1>
<p>In general, we use the information we collect to provide you with the best possible Services, and continually improve them over time.
Specifically, we may use Anonymous Information as well as Personal Information as described elsewhere in this Privacy Policy to improve our Services, to ensure our Services work properly, and for other research and commercial purposes. These purposes include, among other things, to:</p>
<ul>
<li>Make calculations to provide customized Service;</li>
<li>Analyze, improve, and customize the Services;</li>
<li>Provide customer and technical support for the Services;</li>
<li>Conduct surveys, research, and audits;</li>
<li>Receive user feedback;</li>
<li>Provide information to our representatives and/or advisors, such as our attorneys, accountants, and others to help us comply with legal, accounting, or security requirements;</li>
<li>Detect, prevent, and investigate actual or suspected fraud, hacking, infringement, or other misconduct involving the Services.
We will comply with legal requests for information when we have a legal obligation to do so, but we cannot provide information we do not have.</li>
</ul>
<h1 id="how-we-share-information">How We Share Information</h1>
<p>Regulus: Star Diary App does not sell your Personal Information to third parties, nor do we disclose Personal Information to third parties for direct marketing purposes.
We may share Anonymous Information with third parties as described elsewhere in this Privacy Policy and to improve our Services and for other commercial purposes. We take your privacy seriously and we do not share your Personal Information associated with your identity.</p>
<h1 id="we-do-not-sell-personal-information">WE DO NOT SELL PERSONAL INFORMATION</h1>
<p>We have never sold your Personal Information, and we will not sell your Personal Information to third parties for their use without your consent.
When you use our Services, you may be directed to other websites and applications that are beyond our control. We may also allow third-party websites or applications to link to the Services. We are not responsible for the privacy practices of any third parties or the content of linked websites and applications, but we do encourage you to read the applicable privacy policies and terms and conditions of such parties, websites, and applications. This Privacy Policy only applies to our Services.</p>
<h1 id="information-you-share-socially">Information You Share Socially</h1>
<p>Keep in mind that the users who view any content you share (e.g. your profile) can always save that content or copy it outside the app. So, the same common sense that applies to the internet at large applies to Regulus: Star Diary App as well: Don’t share or make public any content that you wouldn’t want someone else to save or share.
How we secure, store, and retain information</p>
<h1 id="security">Security</h1>
<p>We take reasonable technical and organizational precautions to protect the confidentiality, security, and integrity of your Personal Information. We use security measures to help protect your Personal Information against loss, misuse, or unauthorized disclosure, but we cannot guarantee the security of information transmitted to us over the internet. We strive to use commercially acceptable means to protect your Personal Information, but there is no guarantee that information may not be accessed, disclosed, altered, or lost. Therefore, we cannot guarantee absolute security of your Personal Information. You also play a role in protecting your Personal Information. Please safeguard your device and App and do not share them with others.</p>
<h1 id="storage">Storage</h1>
<p>The Personal Information that you provide to us is generally stored on your device. Our third party tool RevenueCat collects purchase history for the App from customers.
International users
Regulus: Star Diary App is headquartered in Turkey, but be aware that third party services may be located around the world.
We use email to respond to support inquiries and to share information about our products, services, and promotional offers that we think may interest you. If you prefer to know more about our Services, please let us know by emailing app.regulus@gmail.com.</p>
<h1 id="contact-us">Contact Us</h1>
<p>If you have any questions or comments, please contact us:</p>
<ul>
<li><strong>By email</strong> at app.regulus@gmail.com</li>
</ul>


    <div className="Section-Padding"/>
    </div>
  );
}
export default PrivacyPolicy;
