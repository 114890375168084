
import '../App.css';

function TermsOfUse() {


  return (
    <div className="App-Legal-Text">
    <div className="Section-Padding"/>

<h1 id="terms-of-use">Terms of Use</h1>
<h2 id="last-updated-february-10-2022">Last Updated: February 10, 2022</h2>
<p>PLEASE READ THIS TERMS OF USE AGREEMENT (THIS “AGREEMENT”) CAREFULLY. BY USING REGULUS: STAR DIARY  (“Regulus App”, “Regulus”,  “app,” “we,” “us,” and “our”) THE USER AGREES THAT THIS AGREEMENT IS ENFORCEABLE AND LEGALLY BINDING. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THE AGREEMENT, PLEASE DO NOT USE THE APP OR APP SERVICES.</p>
<h1 id="1-license">1. License</h1>
<p>1.1. Subject to your agreement to this Terms of Use agreement and its possible following amendments, you may use the app and our service solely for your own non-commercial purposes. You may not use the app or the service for any other purpose, or using any other method.</p>
<p>1.2. Third Party Notices. The App may include third party code that Regulus: Star Diary, not the third party, licenses to you under this agreement. Notices, if any, for the third party code are included for your information only. Third party scripts, linked to, called or referenced from the Software, are licensed to you by the third parties that own such code, not by Regulus: Star Diary.</p>
<h1 id="2-consent">2. Consent</h1>
<p>2.1. By using the app, you agree that Regulus: Star Diary may collect and use technical data and related information including but not limited to technical information about your device, system and application software that is gathered contemporarily to help improve the software and services.</p>
<p>2.2. The service is offered subject to acceptance without any modification of any of the terms and conditions contained herein (the “Terms of Use”) and all other operating rules, policies and procedures that may be published from time to time by Regulus: Star Diary.</p>
<p>2.3. No one under 13 is allowed to create an account or use the Services. If legally required, users between 13 and 18 years of age may use the Services only with permission and supervision of a parent or legal guardian. If you do not meet all of these requirements, you must not access or use the Services.</p>
<h1 id="3-termination">3. Termination</h1>
<p>This Terms of Use continues to be effective until terminated by either you or us. Your right to access and use the services terminates automatically upon your breach of this Terms of Use. Upon the termination, you shall cease all use of the App and delete all copies of the App.</p>
<h1 id="4-services">4. Services</h1>
<p>4.1. You may encounter inappropriate content that may be deemed offensive, indecent or objectionable.</p>
<p>4.2. You agree to use the Services solely at your own risks and we do not have any liability to you for its content.</p>
<p>4.3. You agree not to use the Services in any unauthorised way including but not limited to trespassing or burdening network capacity.</p>
<p>4.4. You further agree to use the Services in your sole discretion and take full responsibility in your actions including but not limited to harassing, abusing, stalking, threatening, defaming or infringing or violating the rights of any other party, and that we are not in any way responsible for any such use by you, nor for any such actions that you may encounter as a result of using the Services.</p>
<h1 id="5-warranty-disclaimer">5. Warranty Disclaimer</h1>
<p>Unless otherwise explicitly stated, the services are provided “as is” and all express or implied conditions, representations and warranties are disclaimed. You acknowledge and agree that the use of the application is at your sole risk and that the entire risk as to satisfactory quality, performance, accuracy and effort is with you. No oral or written information or advice given by us shall create a warranty.</p>
<h1 id="6-limitation-of-liabilities">6. Limitation of Liabilities</h1>
<p>We are not responsible for any damages arising our or your use of the services or your inability to use the services. In no event shall we be liable for any personal injury, or any incidental, special, indirect or consequential damages whatsover, loss of profits, loss of data, business interruption or any other commercial damages or losses, however caused, and even if we have been advised of the possibility of such damages. In no event shall our total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty US dollars ($50.00), which will apply even if the above stated remedy fails of its essential purpose. You may not use or otherwise export or re-export the app except as authorised by the laws of the jurisdiction in which the app was obtained.</p>
<h1 id="7-changes-to-the-terms">7. Changes to the terms</h1>
<p>Regulus: Star Diary may make changes to these Terms from time to time. Changes to the fair usage policies and/or Additional Terms will be posted on the applicable Regulus: Star Diary website and application. The changes will be effective when published. Please review the Terms on a regular basis. You understand and agree that your express acceptance of the Terms or your use of the Software, Products and/or Websites after the date of publication shall constitute your agreement to the updated Terms.</p>
<h1 id="8-general">8. General</h1>
<p>These Terms are personal to you. You can’t assign them, transfer them, or sublicense them unless you get Regulus: Star Diary’s prior written consent. Regulus: Star Diary has the right to assign, transfer, or delegate any of its rights and obligations under these Terms without your consent.</p>
<h1 id="9-regulus-premium">9. Regulus Premium</h1>
<p>Regulus: Star Diary has added the Regulus Premium for more awesome features and it’s an auto-renewing subscription:</p>
<ul>
<li>Payment will be charged to iTunes Account at confirmation of purchase.</li>
<li>Subscription automatically renews unless auto-new is turned off at least 24-hours before the end of the current period.</li>
<li>Account will be charged for renewal within 24-hours prior to the end of the current period, and identify the cost of the renewal.</li>
<li>Subscriptions may be managed by the user and auto-renewal may be turned off by going to the user’s Account Settings after purchase.</li>
<li>Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable.</li>
<li>All personal data is handled under the terms and conditions of Regulus: Star Diary’s privacy policy.</li>
</ul>
<h1 id="contact-us">Contact Us</h1>
<p>If you have any questions or comments, please contact us:</p>
<ul>
<li><strong>By email</strong> at app.regulus@gmail.com</li>
</ul>

    <div className="Section-Padding"/>
    </div>
  );
}
export default TermsOfUse;
