import appStoreCTAImage from '../assets/AppStore.CTA.png'
import bannerBackgroundImage from '../assets/Banner.Layer.Background.png'
import bannerFrontImage from '../assets/Banner.Layer.Front.png'
import natalChartIcon from '../assets/Natal.Chart.Icon@3x.png'
import compatibilityIcon from '../assets/Compatibility.Icon@3x.png'
import planetaryHoursIcon from '../assets/Planetary.Time.Icon@3x.png'
import lunarIcon from '../assets/Lunar.Icon@3x.png'
import transitsIcon from '../assets/Transit.Aspects.Icon@3x.png'
import fixedStarsIcon from '../assets/Fixed.Stars.Icon@3x.png'
import gemIcon from '../assets/Gem.Icon@3x.png'
import aspectsIcon from '../assets/Aspects.Icon@3x.png'
import '../App.css';

function Main() {

  return (
    <div className="App">

        {/* <div className="Navigation-Bar">
          <img src={logo} className="App-Logo" alt="logo"/>
          <h2 className="Navigation-Bar-Title">Regulus: Star Diary</h2>
          <div class="Navigation-Bar-Spacer"></div>
          <Link href="./PrivacyPolicy">
            <button className="Navigation-Bar-Button">Test</button>
          </Link>
          
          <button className="Navigation-Bar-Button" onClick={connectToAppStore}>Download</button>
          <button className="Navigation-Bar-Button" onClick={connectToAppStore}>Privacy Policy</button>
          <button className="Navigation-Bar-Button" onClick={connectToAppStore}>Terms of Use</button>
        </div> */}

        <div className="Banner">
          {/* <img src={mainBannerImage} className="App-main-banner" alt="main-banner" /> */}
          <img src={bannerBackgroundImage} className="Banner-Layer-Background-1" alt="bannerBackgroundImage"/>
          <img src={bannerBackgroundImage} className="Banner-Layer-Background-2" alt="bannerBackgroundImage"/>
          <img src={bannerFrontImage} className="Banner-Layer-Front" alt="bannerFrontImage"/>
          <div className="Banner-Title">
            <h2>Regulus: Star Diary</h2>
          </div>
          <div className="Banner-Subtitle">
            <p>Your all in one astrology guide</p>
          </div>
          <button className="AppStore-CTA-Button"><img src={appStoreCTAImage} className="AppStore-CTA-Button-Image" alt="appStore-CTA" onClick={connectToAppStore} /></button>
        </div>

        <div className="Container-Padding"/>

        <div className="Features-Section">
          <div className="Section-Padding"/>
          <h3 className="Section-Header">Features</h3>

          <div className="Feature-Section-Tags">
            <div className="Feature-Section-Tag">
              <div className="Feature-Section-Tag-Icon">
                <div className="Feature-Section-Tag-Icon-Container"/>
                <img src={natalChartIcon} className="Feature-Section-Tag-Icon-Image" alt="Feature-Section-Tag-Icon"/>
              </div>
              <p className="Feature-Section-Tag-Title">Birth Chart</p>
            </div>
            <div className="Feature-Section-Tag">
              <div className="Feature-Section-Tag-Icon">
                <div className="Feature-Section-Tag-Icon-Container"/>
                <img src={compatibilityIcon} className="Feature-Section-Tag-Icon-Image" alt="Feature-Section-Tag-Icon"/>
              </div>
              <p className="Feature-Section-Tag-Title">Compatibility</p>
            </div>
            <div className="Feature-Section-Tag">
              <div className="Feature-Section-Tag-Icon">
                <div className="Feature-Section-Tag-Icon-Container"/>
                <img src={planetaryHoursIcon} className="Feature-Section-Tag-Icon-Image" alt="Feature-Section-Tag-Icon"/>
              </div>
              <p className="Feature-Section-Tag-Title">Planetary Hours</p>
            </div>
            <div className="Feature-Section-Tag">
              <div className="Feature-Section-Tag-Icon">
                <div className="Feature-Section-Tag-Icon-Container"/>
                <img src={lunarIcon} className="Feature-Section-Tag-Icon-Image" alt="Feature-Section-Tag-Icon"/>
              </div>
              <p className="Feature-Section-Tag-Title">Moon Phase and Sign</p>
            </div>
            <div className="Feature-Section-Tag">
              <div className="Feature-Section-Tag-Icon">
                <div className="Feature-Section-Tag-Icon-Container"/>
                <img src={aspectsIcon} className="Feature-Section-Tag-Icon-Image" alt="Feature-Section-Tag-Icon"/>
              </div>
              <p className="Feature-Section-Tag-Title">Aspects and Planet Positions</p>
            </div>
            <div className="Feature-Section-Tag">
              <div className="Feature-Section-Tag-Icon">
                <div className="Feature-Section-Tag-Icon-Container"/>
                <img src={transitsIcon} className="Feature-Section-Tag-Icon-Image" alt="Feature-Section-Tag-Icon"/>
              </div>
              <p className="Feature-Section-Tag-Title">Transits</p>
            </div>
            <div className="Feature-Section-Tag">
              <div className="Feature-Section-Tag-Icon">
                <div className="Feature-Section-Tag-Icon-Container"/>
                <img src={fixedStarsIcon} className="Feature-Section-Tag-Icon-Image" alt="Feature-Section-Tag-Icon"/>
              </div>
              <p className="Feature-Section-Tag-Title">Fixed Stars</p>
            </div>
            <div className="Feature-Section-Tag">
              <div className="Feature-Section-Tag-Icon">
                <div className="Feature-Section-Tag-Icon-Container"/>
                <img src={gemIcon} className="Feature-Section-Tag-Icon-Image" alt="Feature-Section-Tag-Icon"/>
              </div>
              <p className="Feature-Section-Tag-Title">Gemstone and Color of the Day</p>
            </div>
          </div>

          
          {/* <img src={logo} className="App-Logo" alt="logo"/> */}
          
          {/* <iframe
            src="https://www.youtube.com/embed/PeonBmeFR8o"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
            title="video"
          />{" "} */}
          <div className="Section-Padding"/>
        </div>

        <div className="Container-Padding"/>

        <div className="Available-Languages-Section">
          <div className="Section-Padding"/>
          <h2 className="Section-Header">Available Languages</h2>
          <h3 className="Section-Description">🇬🇧 English &nbsp; 🇹🇷 Turkish &nbsp; 🇨🇳 Chinese &nbsp; 🇫🇷 French &nbsp; 🇪🇸 Spanish &nbsp; 🇵🇹 Portuguese &nbsp; 🇩🇪 German</h3>
          <div className="Section-Padding"/>
        </div>

        <div className="Container-Padding"/>

        

      {/* <Stack direction="column" spacing={0}>
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </div>

        <div className="Banner">
          <img src={mainBannerImage} className="App-main-banner" alt="main-banner" />
          <button className="AppStore-CTA-button"><img src={appStoreCTAImage} className="AppStore-CTA-button-image" alt="appStore-CTA" onClick={connectToAppStore} /></button>
        </div>

        <div className="FeaturesSection">
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        </div>
      </Stack> */}
      
      
        {/* <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <h1 className="text-3xl font-bold underline">
          Hello world!
        </h1>
        
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      
    </div>
  );
}

function connectToAppStore() {
  window.open('https://t.co/hGL1uFySut', '_blank');
}

export default Main;
